import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=6c7ac14a&"
import script from "./Menu.vue?vue&type=script&lang=js&"
export * from "./Menu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Burger: require('/codebuild/output/src1179806432/src/eddys/components/global/Burger.vue').default,LanguageSwitch: require('/codebuild/output/src1179806432/src/eddys/components/global/LanguageSwitch.vue').default})
